import Vue from 'vue/dist/vue.esm'
import VueResource from 'vue-resource';

Vue.use(VueResource);

document.addEventListener('DOMContentLoaded', () => {
  let rootElement = document.querySelector('#sidebar');
  if (!rootElement) {return}
  Vue.http.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

  new Vue({
    el: rootElement,
    data: {
      active: rootElement.dataset.active
    },
    created: function () {
      let vm = this;
    },
    mounted() {
      let activePanel = document.getElementById(`${this.active}_panel`);
      if (activePanel) {
        activePanel.classList.remove('hidden')
      }
    },
    methods: {
      triggerMenu(event) {
        if (this.active === event.target.id) {
          this.active = null
          document.getElementById(`${event.target.id}_panel`).classList.add('hidden')
        } else {
          this.active = event.target.id
          let all_affected = document.getElementsByClassName('accordion-panel')
          for (let element of all_affected) {
            if (element.id === `${event.target.id}_panel`) {
              element.classList.remove('hidden')
            } else {
              element.classList.add('hidden')
            }
          }
        }
      },
    }
  })
});